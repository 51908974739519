@import "../css/variables.module.scss";

// this is loosely based on the longest hint text
$wide-viewport-width: 1000px;

.excalidraw {
  .HintViewer {
    pointer-events: none;
    box-sizing: border-box;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    top: 100%;
    max-width: 100%;
    width: 100%;
    margin-top: 0.5rem;
    text-align: center;
    color: var(--color-gray-40);
    font-size: 0.75rem;

    @include isMobile {
      position: static;
      padding-right: 2rem;
    }

    > span {
      padding: 0.25rem;
    }
  }

  &.theme--dark {
    .HintViewer {
      color: var(--color-gray-60);
    }
  }
}
