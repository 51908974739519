@import "../css/variables.module.scss";

.excalidraw {
  .confirm-dialog {
    &-buttons {
      display: flex;
      column-gap: 0.5rem;
      justify-content: flex-end;
    }
  }
}
