.excalidraw {
  --list-border-color: var(--color-gray-20);

  .QuickSearch__wrapper {
    position: relative;
    height: 2.6rem; // added +0.1 due to Safari
    border-bottom: 1px solid var(--list-border-color);

    svg {
      position: absolute;
      top: 47.5%; // 50% is not exactly in the center of the input
      transform: translateY(-50%);
      left: 0.75rem;
      width: 1.25rem;
      height: 1.25rem;
      color: var(--color-gray-40);
      z-index: 1;
    }
  }

  &.theme--dark {
    --list-border-color: var(--color-gray-80);

    .QuickSearch__wrapper {
      border-bottom: none;
    }
  }

  .QuickSearch__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    border: 0 !important;
    font-size: 0.875rem;
    padding-left: 2.5rem !important;
    padding-right: 0.75rem !important;

    &::placeholder {
      color: var(--color-gray-40);
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}
