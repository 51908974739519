.excalidraw {
  .ExcalidrawLogo {
    --logo-icon--xs: 2rem;
    --logo-text--xs: 1.5rem;

    --logo-icon--small: 2.5rem;
    --logo-text--small: 1.75rem;

    --logo-icon--normal: 3rem;
    --logo-text--normal: 2.2rem;

    --logo-icon--large: 90px;
    --logo-text--large: 65px;

    display: flex;
    align-items: center;

    svg {
      flex: 0 0 auto;
    }

    .ExcalidrawLogo-icon {
      width: auto;
      color: var(--color-logo-icon);
    }

    .ExcalidrawLogo-text {
      margin-left: 0.75rem;
      width: auto;
      color: var(--color-logo-text);
    }

    &.is-xs {
      .ExcalidrawLogo-icon {
        height: var(--logo-icon--xs);
      }

      .ExcalidrawLogo-text {
        height: var(--logo-text--xs);
      }
    }

    &.is-small {
      .ExcalidrawLogo-icon {
        height: var(--logo-icon--small);
      }

      .ExcalidrawLogo-text {
        height: var(--logo-text--small);
      }
    }

    &.is-normal {
      .ExcalidrawLogo-icon {
        height: var(--logo-icon--normal);
      }

      .ExcalidrawLogo-text {
        height: var(--logo-text--normal);
      }
    }

    &.is-large {
      .ExcalidrawLogo-icon {
        height: var(--logo-icon--large);
      }

      .ExcalidrawLogo-text {
        height: var(--logo-text--large);
      }
    }
  }
}
