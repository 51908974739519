.excalidraw {
  .dialog-mermaid {
    &-title {
      margin-block: 0.25rem;
      font-size: 1.25rem;
      font-weight: 700;
      padding-inline: 2.5rem;
    }
  }
}
